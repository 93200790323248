import React from 'react';
import { graphql } from 'gatsby';

// components
import Container from '../components/Container';
import GemstonePreview from '../components/GemstonePreview';

// styles
import styles from '../css/pages/gemstones.module.css';

export default ({ data, location }) => {
  const gemstonesData = data.allDatoCmsGemstone.edges;
  const gemstones = gemstonesData.map(edge => (
    <GemstonePreview key={edge.node.id} gemstone={edge.node} />
  ));

  return (
    <Container title="Kameny" permalink={location.pathname}>
      <div className="wrap">
        <div className={styles.gemstones}>{gemstones}</div>
      </div>
    </Container>
  );
};

export const query = graphql`
  query {
    allDatoCmsGemstone(filter: { locale: { eq: "cs" } }) {
      edges {
        node {
          id
          name
          slug
          subtitle
          shortDescriptionNode {
            childMarkdownRemark {
              html
            }
          }
          featuredImage {
            fluid(
              maxWidth: 450
              imgixParams: { fm: "jpg", auto: "compress", fit: "crop", w: "450", h: "500" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
