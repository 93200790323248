import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

// styles
import styles from '../css/components/GemstonePreview.module.css';

const GemstonePreview = ({ gemstone }) => {
  const name = gemstone.name;
  const subtitle = gemstone.subtitle;
  const image = gemstone.featuredImage.fluid;
  const slug = gemstone.slug;

  return (
    <article className={styles.gemstone}>
      {/* image */}
      <div className={styles.image}>
        <Link to={`/kameny/${slug}`}>
          <Img fluid={image} />
        </Link>
      </div>

      {/* body */}
      <div className={styles.body}>
        <div className={styles.header}>
          <h2 className={styles.name}>{name}</h2>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        <div className={styles.link}>
          <Link to={`/kameny/${slug}`}>Více o kameni &rarr;</Link>
        </div>
      </div>
    </article>
  );
};

export default GemstonePreview;
